<template>
    <v-app id="app-bg">
        <home-app-bar />

        <home-view />

        <home-footer />

        <!-- <home-settings /> -->
    </v-app>
</template>

<script>
export default {
    name: `HomeLayout`,

    components: {
        HomeAppBar: () => import(`@/layouts/home/app_bar`),
        HomeView: () => import(`@/layouts/home/view`),
        HomeFooter: () => import(`@/layouts/home/footer`)
        // HomeSettings: () => import(`@/layouts/home/settings`),
    }
}
</script>

<style>
#app-bg {
    background-color: #485461;
    background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);
}
</style>
